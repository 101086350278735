import {
  getFcMediaTrackingIdFromCookie,
  setFcMediaTrackingId,
} from "../data/fcmedia-tracking-repository";

export const useSetFcMediaTracking = (): ((trackingId: string) => void) => {
  return (trackingId: string): void => setFcMediaTrackingId(trackingId);
};

export const useFcMediaTrackingFromCookie = (): string | undefined => {
  return getFcMediaTrackingIdFromCookie();
};
